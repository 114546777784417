import React from 'react';

const Img5 = () => (
  <svg width="41" height="68" viewBox="0 0 41 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.7813 38.5087L24.7387 38.5197C23.9819 38.7203 23.2142 38.8713 22.4425 38.9702C20.2108 39.2587 17.9379 39.1212 15.7487 38.5513C15.6952 38.5377 15.6415 38.5225 15.588 38.5087C-0.708083 34.1895 -1.30826 21.6467 0.967346 12.0388C2.02626 7.5658 3.70859 3.72871 4.72076 1.65219C5.21376 0.640023 6.24106 0 7.36585 0H33.0036C34.1284 0 35.1557 0.640023 35.6487 1.65219C36.6608 3.72871 38.3432 7.5658 39.4021 12.0388C41.6776 21.6467 41.0774 34.1895 24.7813 38.5087Z" fill="#D9D9D9"/>
    <path d="M22.8624 32.8436H17.5066V64.7727H22.8624V32.8436Z" fill="#D9D9D9"/>
    <path d="M19.582 61.6555L6.13035 65.6419C4.45413 66.1386 4.92986 68.0001 6.73292 68.0001H33.6361C35.4391 68.0001 35.9149 66.1386 34.2387 65.6419L20.7871 61.6555C20.4 61.5406 19.969 61.5406 19.582 61.6555Z" fill="#D9D9D9"/>
    <path d="M24.7813 38.5087L24.7387 38.5198C23.9819 38.7203 23.2142 38.8713 22.4425 38.9703C20.2108 39.2587 17.9379 39.1213 15.7487 38.5514C15.6952 38.5377 15.6415 38.5226 15.588 38.5087C-0.708083 34.1896 -1.30826 21.6467 0.967346 12.0388H39.4018C41.6776 21.6467 41.0774 34.1896 24.7813 38.5087Z" fill="#F00C2C"/>
    <path d="M24.7387 37.2387L24.7813 37.2277C36.6217 34.0895 40.176 26.6098 40.3615 19.0689C40.5519 27.0297 37.2922 35.1927 24.7813 38.5087L24.7387 38.5197C23.9819 38.7202 23.2142 38.8713 22.4425 38.9702C20.2108 39.2587 17.9379 39.1212 15.7487 38.5513C15.7206 38.5441 15.6925 38.5366 15.6644 38.529C15.6389 38.5221 15.6135 38.5152 15.588 38.5087C3.07696 35.1927 -0.1827 27.0296 0.00776831 19.0687C0.193285 26.6097 3.7475 34.0894 15.588 37.2277C15.6135 37.2343 15.6389 37.2411 15.6644 37.248C15.6925 37.2556 15.7206 37.2632 15.7487 37.2703C17.9379 37.8402 20.2108 37.9777 22.4425 37.6892C23.2142 37.5903 23.9819 37.4393 24.7387 37.2387Z" fill="#D9D9D9"/>
  </svg>
);

export default Img5;