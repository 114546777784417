import React from "react";
import { Controller } from "react-hook-form";
import {
  InputNumber,
} from "antd";

const Question24 = ({control}) => (
  <div className="question">
    <label htmlFor="Fahrleistung">
      24. Wie hoch ist ihre berufliche Fahrleistung pro Jahr?
    </label>
    <Controller
      as={
        <InputNumber
          size="large"
          defaultValue={0}
          placeholder="Anzahl der Kilometer"
        />
      }
      control={control}
      name="Fahrleistung"
      id="Fahrleistung"
    />
  </div>
);

export default Question24