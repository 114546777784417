import React from 'react';

const Img3 = () => (
  <svg width="27" height="68" viewBox="0 0 27 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2531 68.0001H9.3912C8.26282 68.0001 7.34766 67.0854 7.34766 65.9571V58.0947C7.34766 56.9663 8.26282 56.0516 9.3912 56.0516H17.2531C18.3815 56.0516 19.2961 56.9663 19.2961 58.0947V65.9571C19.2961 67.0854 18.3815 68.0001 17.2531 68.0001Z" fill="#525252"/>
    <path d="M19.6303 60.1381H7.01312C3.146 60.1381 0 56.9916 0 53.125V7.01312C0 3.146 3.146 0 7.01312 0H19.6303C23.4974 0 26.6434 3.146 26.6434 7.01312V53.125C26.6434 56.9916 23.4974 60.1381 19.6303 60.1381Z" fill="#09686B"/>
    <path d="M13.3216 21.2266C9.17175 21.2266 5.79541 17.8507 5.79541 13.7009C5.79541 9.55099 9.17175 6.17517 13.3216 6.17517C17.4715 6.17517 20.8473 9.55099 20.8473 13.7009C20.8473 17.8507 17.4715 21.2266 13.3216 21.2266Z" fill="#F00C2C"/>
  </svg>
);

export default Img3;