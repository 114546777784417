import React from 'react';

const Img1 = () => (
  <svg width="68" height="51" viewBox="0 0 68 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.0546 18.915C41.084 18.396 39.8766 18.7622 39.3576 19.7329L35.6372 26.6937C35.1047 26.5734 34.5509 26.51 33.9826 26.51C29.8441 26.51 26.4768 29.8772 26.4768 34.0163C26.4768 34.0998 26.478 34.1831 26.4807 34.2659C26.5477 36.3049 27.4317 38.1408 28.8152 39.4558C30.077 40.6259 31.6828 41.1987 32.3279 41.3389C32.8604 41.4592 33.4141 41.5227 33.9824 41.5227C38.121 41.5227 41.4883 38.1554 41.4883 34.0163C41.4883 33.9328 41.4871 33.8496 41.4844 33.7667C41.4174 31.7277 40.5334 29.8918 39.1499 28.5768L42.8725 21.6116C43.3915 20.6409 43.0248 19.4335 42.0546 18.915Z" fill="#525252"/>
    <path d="M28.4606 0.453945C21.8092 1.54002 15.6235 4.58029 10.6445 9.28C10.4042 9.50687 10.169 9.73737 9.93646 9.96993C4.056 15.8478 0.550939 23.5966 0.0407436 31.9016C0.0338244 32.0144 0.0365535 32.1266 0.0485252 32.2374C-0.262583 38.0433 0.923769 43.8567 3.56406 49.1558C3.80917 49.6477 4.24691 50.0164 4.77322 50.1749C5.29952 50.3333 5.86807 50.2676 6.344 49.9929L13.6575 45.7704C10.4169 40.1574 9.76167 33.7497 11.3197 27.935C12.8777 22.1204 16.649 16.8987 22.262 13.6581C27.8749 10.4174 34.2826 9.76222 40.0973 11.3203L40.0986 11.3154C44.1265 12.4017 47.7296 14.534 50.5973 17.4017L58.0285 9.97045C57.796 9.73737 57.5608 9.50687 57.3205 9.28C52.6736 4.89377 46.9756 1.95299 40.829 0.697273C36.711 -0.147613 32.5266 -0.219734 28.4606 0.453945Z" fill="#09686B"/>
    <path d="M56.692 27.9318L56.6804 27.935C58.2384 33.7496 57.5832 40.1574 54.3426 45.7703L61.6561 49.9928C62.132 50.2676 62.7005 50.3332 63.2268 50.1748C63.7536 50.0166 64.1909 49.6476 64.436 49.1558C67.5884 42.8289 68.6681 35.769 67.6011 28.8643C66.5157 21.7183 63.1855 15.1246 58.0284 9.97021L50.5972 17.4015C53.4724 20.2767 55.6084 23.8912 56.692 27.9318Z" fill="#F00C2C"/>
  </svg>
);

export default Img1;