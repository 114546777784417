import React from 'react';

const Img4 = () => (
  <svg width="87" height="41" viewBox="0 0 87 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M48.1641 31.81H0.5V0.303223H18.8343L27.4418 13.8651H39.7841C41.8629 13.8651 43.7466 14.9456 44.981 16.6686H42.5774C40.8233 16.6686 39.3812 18.2747 39.3812 20.2604C39.3812 22.2463 40.8235 23.8524 42.5774 23.8524H46.9428L48.1641 31.81Z" fill="#09686B"/>
    <path d="M11.1852 14.5419L11.1338 2.29492H17.7028L25.5315 14.5419H11.1852Z" fill="white"/>
    <path d="M9.34352 14.542H0.5V2.29504H9.32147L9.34352 14.542Z" fill="white"/>
    <path d="M30.9723 40.6967C35.776 40.6967 39.6702 36.8025 39.6702 31.9988C39.6702 27.1951 35.776 23.3009 30.9723 23.3009C26.1686 23.3009 22.2744 27.1951 22.2744 31.9988C22.2744 36.8025 26.1686 40.6967 30.9723 40.6967Z" fill="#525252"/>
    <path d="M30.9724 37.8668C34.2134 37.8668 36.8406 35.2395 36.8406 31.9986C36.8406 28.7576 34.2134 26.1304 30.9724 26.1304C27.7315 26.1304 25.1042 28.7576 25.1042 31.9986C25.1042 35.2395 27.7315 37.8668 30.9724 37.8668Z" fill="#D9D9D9"/>
    <path d="M86.444 0.303223H69.2335L59.5975 13.8132H53.0112V27.3695C53.0112 29.7549 54.9457 31.6894 57.3456 31.6894H86.444V0.303223Z" fill="#09686B"/>
    <path d="M70.764 2.29504H75.5057V14.542H61.906L70.764 2.29504Z" fill="white"/>
    <path d="M86.5 14.5419H77.7146V2.29492H86.4781L86.5 14.5419Z" fill="white"/>
    <path d="M71.4132 40.6967C76.217 40.6967 80.1111 36.8025 80.1111 31.9988C80.1111 27.1951 76.217 23.3009 71.4132 23.3009C66.6095 23.3009 62.7153 27.1951 62.7153 31.9988C62.7153 36.8025 66.6095 40.6967 71.4132 40.6967Z" fill="#525252"/>
    <path d="M71.4134 37.8668C74.6543 37.8668 77.2816 35.2395 77.2816 31.9986C77.2816 28.7576 74.6543 26.1304 71.4134 26.1304C68.1724 26.1304 65.5452 28.7576 65.5452 31.9986C65.5452 35.2395 68.1724 37.8668 71.4134 37.8668Z" fill="#D9D9D9"/>
    <path d="M59.5972 18.1186V22.9768C59.5972 23.7476 58.9719 24.3731 58.2009 24.3731H53.011V16.7224H58.2009C58.9717 16.7222 59.5972 17.3478 59.5972 18.1186Z" fill="#F00C2C"/>
  </svg>
);

export default Img4;