import React from 'react';

const Img2 = () => (
  <svg width="44" height="68" viewBox="0 0 44 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.8401 5.59317V62.4001C41.8401 64.399 40.2194 66.0197 38.2209 66.0197H5.61548C3.61655 66.0197 1.99634 64.399 1.99634 62.4001V5.59317C1.99634 3.59424 3.61655 1.97351 5.61548 1.97351H38.2209C40.2194 1.97351 41.8401 3.59424 41.8401 5.59317Z" fill="white"/>
    <path d="M36.5193 0H7.30884C3.27933 0 0 3.27933 0 7.30936V60.6906C0 64.7207 3.27933 68 7.30884 68H36.5193C40.5488 68 43.8281 64.7207 43.8281 60.6906V7.30936C43.8281 3.27933 40.5488 0 36.5193 0V0ZM39.8438 60.6906C39.8438 62.5236 38.3522 64.0151 36.5193 64.0151H7.30884C5.47592 64.0151 3.98438 62.5236 3.98438 60.6906V7.30936C3.98438 5.47644 5.47592 3.98489 7.30884 3.98489H11.4712C11.576 6.58304 13.7207 8.6629 16.3427 8.6629H27.4854C30.1075 8.6629 32.2522 6.58252 32.357 3.98489H36.5193C38.3522 3.98489 39.8438 5.47644 39.8438 7.30936V60.6906Z" fill="#09686B"/>
    <path d="M27.8906 59.3372C27.8906 60.4386 26.9983 61.3293 25.8984 61.3293H17.9297C16.8298 61.3293 15.9375 60.438 15.9375 59.3372C15.9375 58.2373 16.8298 57.345 17.9297 57.345H25.8984C26.9983 57.345 27.8906 58.2373 27.8906 59.3372Z" fill="#C9D211"/>
  </svg>
);

export default Img2;