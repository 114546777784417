import React, { useState, useEffect } from "react";
import {Controller, useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import Question1 from "./Questions/Question1";
import {Col, Row, message, Input} from "antd";
import axios from "axios";
import { v1 as uuidv1 } from "uuid";
import disableScroll from "disable-scroll";
import Modal from "./Modal";
import Question2 from "./Questions/Question2";
import Question3 from "./Questions/Question3";
import Question4 from "./Questions/Question4";
import Question5 from "./Questions/Question5";
import Question6 from "./Questions/Question6";
import Question7 from "./Questions/Question7";
import Question8 from "./Questions/Question8";
import Question9 from "./Questions/Question9";
import Question10 from "./Questions/Question10";
import Question12 from "./Questions/Question12";
import Question13 from "./Questions/Question13";
import Question14 from "./Questions/Question14";
import Question15 from "./Questions/Question15";
import Question11 from "./Questions/Question11";
import Question16 from "./Questions/Question16";
import Question17 from "./Questions/Question17";
import Question18 from "./Questions/Question18";
import Question19 from "./Questions/Question19";
import Question20 from "./Questions/Question20";
import Question21 from "./Questions/Question21";
import Question22 from "./Questions/Question22";
import Question23 from "./Questions/Question23";
import Question24 from "./Questions/Question24";
import Question25 from "./Questions/Question25";
import Question26 from "./Questions/Question26";
import Question27 from "./Questions/Question27";
import Question28 from "./Questions/Question28";
import Question29 from "./Questions/Question29";
import Question30 from "./Questions/Question30";
import Question31 from "./Questions/Question31";
import Aktenzeichen from "./Questions/Aktenzeichen";

const id = uuidv1();
const CONTACT_API_PATH = "/api/contact/mailer.php";
const UPLOAD_API_PATH = "/api/upload/upload.php";
const POSTCODES_API_PATH = "https://api.zippopotam.us/de/";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Form = () => {
  let query = useQuery();

  const {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
    register
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `${CONTACT_API_PATH}`,
      headers: { "content-type": "application/json" },
      data: data
    })
      .then(response => {
        onOpenModal();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const [checkedRadioImageIndex, SetCheckedRadioImageIndex] = useState(null);

  const onChangeRadioImage = (value, index) => {
    setValue("Unfalldaten.Unfallart", value);
    SetCheckedRadioImageIndex(index);
  };

  const propsForDrager = {
    multiple: true,
    beforeUpload: (file, fileList) => {
      if (file.size > 10000000) {
        message.error(
          `Upload hat nicht geklappt. Datei überschreitet maximale Größe (10 MB)`
        );
        file.flag = true;
        return false;
      }
    },
    customRequest: ({ file, onSuccess, onError }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      axios({
        method: "post",
        url: UPLOAD_API_PATH,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData
      })
        .then(function(response) {
          message.success(`${file.name}. Upload war erfolgreich.`);
          onSuccess();
        })
        .catch(function(error) {
          message.error(`Upload hat nicht geklappt. ${error.response.data}`);
          onError();
        });
    }
  };

  useEffect(() => {
    const firstErrorInput = document.querySelector(".input-error");
    if (firstErrorInput && firstErrorInput.classList.contains("ant-input")) {
      firstErrorInput.focus();
    } else if (
      firstErrorInput &&
      !firstErrorInput.classList.contains("ant-input")
    ) {
      firstErrorInput.querySelector("input").focus();
    }
  }, [errors]);

  const isFahrverbotFrage = watch("Fahrverbot.Frage"),
    isFahrverbotFrage2 = watch("Fahrverbot.Frage2"),
    BeschaftigungFrage = watch("Beschaftigung.Frage"),
    isAuslandFrage = watch("Ausland.Frage"),
    TaetigkeitChechboxGroup = watch("Taetigkeit.ChechboxGroup"),
    isMitfahrgelegenheitFrage = watch("Mitfahrgelegenheit.Frage"),
    isArbeitgeber = watch("Arbeitgeber"),
    isFahrverbotUrlaub = watch("Fahrverbot.Urlaub"),
    FahrverbotGruende = watch("Fahrverbot.Gruende"),
    isBussBehorde = watch("Buss.Behorde");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCloseModal = event => {
    setIsModalOpen(false);
    document.location.href = "https://www.brs-ag.de/";
  };

  const onOpenModal = event => {
    setIsModalOpen(true);
  };

  const clearNumericMaskInput = value => {
    return value.replace(/[^0-9]/g, "");
  };

  const onPlzChange = (event, cityField) => {
    const plz = clearNumericMaskInput(event.target.value);
    if (plz.length === 5) {
      axios({
        method: "get",
        url: `${POSTCODES_API_PATH}${plz}`
      }).then(
        function({ data }) {
          const city = data.places[0]["place name"];
          if (city) {
            setValue(cityField, city);
          }
        },
        function(err) {
          console.log(err);
        }
      );
    }
    return event.target.value;
  };

  useEffect(() => {
    if (isModalOpen && window.innerWidth >= 1200) {
      disableScroll.on();
    } else if (isModalOpen && window.innerWidth < 1200) {
      document.body.style.overflow = "hidden";
    } else {
      disableScroll.off();
      document.body.style.overflow = "unset";
    }
  }, [isModalOpen]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={30}>
          <Col xs={{order: 2}} sm={{span: 16, order: 1}}>
            <Question1 control={control} value={query.get("geschlecht")}/>
          </Col>
          <Col xs={{order: 1}} sm={{ span: 8, order: 2 }}>
            <Aktenzeichen
              control={control}
              errors={errors}
              value={query.get("Aktenzeichen")}
            />
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Question2
              control={control}
              errors={errors}
              value={query.get("vorname")}
            />
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Question3 control={control} errors={errors} value={query.get("nachname")}/>
          </Col>
        </Row>
        <Question4 control={control} errors={errors} value={query.get("telefon")}/>
        <Question5 control={control} errors={errors} value={query.get("email")}/>
        <Question6 control={control} errors={errors} value={query.get("strase")}/>
        <Row gutter={30}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Question7
              control={control}
              errors={errors}
              onPlzChange={onPlzChange}
              value={query.get("plz")}
            />
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Question8 control={control} errors={errors} value={query.get("stadt")}/>
          </Col>
        </Row>
        <Question9 control={control} value={query.get("rechtsschutzersicherung")}/>
        <Question10 control={control} value={query.get("schadennummer")}/>
        <Question11
          control={control}
          errors={errors}
          onChangeRadioImage={onChangeRadioImage}
          checkedRadioImageIndex={checkedRadioImageIndex}
        />
        <Question12 control={control} />
        <Question13 control={control} isBussBehorde={isBussBehorde} />
        <Question14 control={control} />
        <Question15
          control={control}
          propsForDrager={propsForDrager}
        />
        <Question16
          control={control}
          propsForDrager={propsForDrager}
        />
        <Question17
          control={control}
          propsForDrager={propsForDrager}
        />
        <Question18
          control={control}
          propsForDrager={propsForDrager}
        />
        <div className="message-info">
          <span>
            Sofern Ihnen ein Fahrverbot droht und eine Umwandlung / ein Absehen
            vom Fahrverbot beantragt werden soll, benötigen wir von Ihnen
            weitere Informationen und Nachweise.
          </span>
        </div>
        <Question19 control={control} isFahrverbotFrage={isFahrverbotFrage} />
        {isFahrverbotFrage2 === "Ja" && isFahrverbotFrage === "Ja" ? (
          <>
            <Question20
              BeschaftigungFrage={BeschaftigungFrage}
              control={control}
              errors={errors}
              onPlzChange={onPlzChange}
            />
            <Question21 isAuslandFrage={isAuslandFrage} control={control} />
            <Question22 control={control} />
            <Question23
              TaetigkeitChechboxGroup={TaetigkeitChechboxGroup}
              control={control}
            />
            <Question24 control={control} />
            <Question25
              isMitfahrgelegenheitFrage={isMitfahrgelegenheitFrage}
              control={control}
            />
            <Question26 isArbeitgeber={isArbeitgeber} control={control} />
            <Question27
              isFahrverbotUrlaub={isFahrverbotUrlaub}
              BeschaftigungFrage={BeschaftigungFrage}
              FahrverbotGruende={FahrverbotGruende}
              control={control}
            />
            <Question28 control={control} />
            <Question29 control={control} />
            <div className="message-info">
              <span>
                Die benannten Gründe für die Umwandlung müssen gegenüber der
                Behörde bzw. dem Gericht zwingend glaubhaft gemacht werden.
                Hierfür benötigen wir von Ihnen nachweise wie:
              </span>
              <ul>
                <li>Einkommensnachweise;</li>
                <li>Arbeitsvertrag/ Gewerbeanmeldung;</li>
                <li>Stellungnahme des Arbeitgebers;</li>
              </ul>
              <span>
                die sie uns jetzt (
                <a
                  className="link"
                  href="https://www.google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hier klicken
                </a>
                ) oder zeitnah per E-Mail an die:{" "}
                <a className="link" href="mailto:info@brs-ag.de">
                  info@brs-ag.de
                </a>{" "}
                einreichen können. Nähere Informationen senden wir Ihnen nach
                Abschließen des Formulars per E-Mail.
              </span>
            </div>
          </>
        ) : null}
        <Question30 control={control} />
        <Question31
          control={control}
          propsForDrager={propsForDrager}
        />
        <input type="hidden" value={id} name="id" ref={register} />
        <button className="btn" type="submit">
          Daten senden
        </button>
        <p className="message-info message-success">
          <span>
            Bitte überprüfen Sie Ihre Eingaben vor dem Absenden auf Richtigkeit!
          </span>
        </p>
      </form>
      <Modal isOpen={isModalOpen} onClose={onCloseModal} bgColor="#ffffff">
        <div className="modal-content">
          <div className="modal-icon">
            <svg
              className="checkMark"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="checkMark-bg"
                d="M53.76 0H2.24C1.001 0 0 1.001 0 2.24V53.76C0 54.999 1.001 56 2.24 56H53.76C54.999 56 56 54.999 56 53.76V2.24C56 1.001 54.999 0 53.76 0Z"
                fill="#FFCC00"
              />
              <path
                className="checkMark-item"
                d="M16 25.5L24.5 36.5L38.5 17.5"
                stroke="none"
              />
            </svg>
          </div>
          <h1 className="modal-title">
            Vielen Dank für Ihre Angaben und die Übersendung der
            Verfahrensunterlagen!
          </h1>
          <div className="modal-description">
            <p>
              Wir werden uns nunmehr für Sie legitimieren und Akteneinsicht
              beantragen.
            </p>
            <p>Nach Erhalt der Akte kommen wir auf den Vorgang zurück.</p>
            <p>
              Sollten Sie zum Verfahren weitere Unterlagen erhalten, so reichen
              Sie uns diese bitte stets unaufgefordert und unter Angabe des
              Aktenzeichens ein.
            </p>
          </div>
          <div className="modal-btnWrapper">
            <button className="btn" onClick={onCloseModal}>
              Abschließen
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Form;
