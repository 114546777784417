import React from "react";
import { Controller } from "react-hook-form";
import bussgeldstelle from "../../data/bussgeldstelle";
import { Input, Select } from "antd";

const { Option } = Select;

const Question13 = ({ control, isBussBehorde }) => (
  <div className="question">
    <label htmlFor="Buss.Behorde">
      13. Bußgeldstelle / Ausstellende Behörde
    </label>
    <Controller
      as={
        <Select
          placeholder="Bußgeldstelle / Ausstellende Behörde"
          style={{ width: "100%" }}
          showSearch={true}
          optionFilterProp="children"
          size="large"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
          }
        >
          {bussgeldstelle.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.name}
            </Option>
          ))}
        </Select>
      }
      control={control}
      name="Buss.Behorde"
      id="Buss.Behorde"
    />
    {isBussBehorde === 1 ? (
      <div className="subquestions">
        <div className="subquestion">
          <label htmlFor="Buss.BehordeCustom">
            Bußgeldstelle / Ausstellende Behörde hier eintragen
          </label>
          <Controller
            as={
              <Input placeholder="Bußgeldstelle / Ausstellende Behörde hier eintragen" size="large"/>
            }
            control={control}
            name="Buss.BehordeCustom"
            id="Buss.BehordeCustom"
          />
        </div>
      </div>
    ) : null}
  </div>
);

export default Question13;
